import React from 'react';
import { Text, Flex, Icon } from '@strikelabs/luna';
import PropTypes from 'prop-types';

import ManDancing from 'images/man-dancing-click-fingers.png';
import {
  Outer,
  ColumnWrapper,
  ContentContainer,
  ImageContainer,
  Image,
  IconWrapper,
} from './style';

const WhatsIncluded = ({ title, list, image }) => (
  <Outer>
    <ColumnWrapper>
      <ImageContainer>
        <Image src={image} />
      </ImageContainer>
      <ContentContainer>
        <Text as="h3" styleType="h2" ext mb={[2, 2, 2, 2, 4]}>
          {title}
        </Text>
        {list.map((listItem, idx) => {
          return (
            <Flex mb={3} key={idx}>
              <IconWrapper>
                <Icon type="check" width="18" />
              </IconWrapper>
              <Text as="p" ext>
                {listItem}
              </Text>
            </Flex>
          );
        })}
      </ContentContainer>
    </ColumnWrapper>
  </Outer>
);

WhatsIncluded.propTypes = {
  title: PropTypes.string.isRequired,
  list: PropTypes.arrayOf(PropTypes.string).isRequired,
  image: PropTypes.string,
};

WhatsIncluded.defaultProps = {
  image: ManDancing,
};

export default WhatsIncluded;
