import styled from 'styled-components';
import { Flex, Box } from '@strikelabs/luna';
import { rem } from 'polished';

import { Container } from 'components/common';

export const Outer = styled(Container)`
  margin-top: ${({ theme }) => theme.spacing.xl};
  margin-bottom: ${({ theme }) => theme.spacing.xl};

  ${({ theme }) => theme.media.desktop} {
    margin-top: ${({ theme }) => theme.spacing.xxl};
    margin-bottom: ${({ theme }) => theme.spacing.xxl};
  }
`;

export const ColumnWrapper = styled(Flex)`
  flex-direction: column;

  ${({ theme }) => theme.media.desktop} {
    flex-direction: row;
    align-items: flex-start;
  }
`;

export const ContentContainer = styled(Flex)`
  width: 100%;
  flex-direction: column;

  ${({ theme }) => theme.media.desktop} {
    width: 50%;
    padding-left: ${({ theme }) => theme.spacing.xl};
  }
`;

export const ImageContainer = styled(Flex)`
  height: ${rem(330)};
  width: 114%;
  margin-bottom: ${({ theme }) => theme.spacing.lg};
  margin-left: ${({ theme }) => `-${theme.defaults.gutterSpacing}`};

  ${({ theme }) => theme.media.desktop} {
    width: 50%;
    margin: 0;
    height: ${rem(662)};
  }
`;

export const Image = styled.div`
  width: 100%;
  background-image: url(${({ src }) => src});
  background-position: top;
  background-size: cover;

  ${({ theme }) => theme.media.desktop} {
    background-position: center;
  }
`;

export const IconWrapper = styled(Box).attrs({
  mt: 0,
  mr: 1,
})`
  min-width: 18px;
`;
